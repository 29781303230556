import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_CLIENTID,
          authority: process.env.VUE_APP_AUTHORITY
        }
      },
      accessToken: '',
      homeAccountId: '',
      msalInstance: {}
    };
  }
});

export default store;