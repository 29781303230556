<template>

  <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img src="/enter-logo.png" style="height:20px;" /><br/>{{siteName}}</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="https://www.enter.fi/fi/ruutuvihko-portaali-tietosuojaseloste/" target="_blank">Tietosuojaseloste</a>
          </li>        
          <li class="nav-item">
            <a class="nav-link" href="#" @click="Logout()">Kirjaudu ulos</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="container" style="margin-top :100px;">
    <div class="row">
      <div class="input-group">
        <span class="input-group-text" id="basic-addon1"><i v-bind:class="searchIcon"></i></span>
        <input id="" type="text" class="form-control" placeholder="Hae oppilasta..." v-model="searchString" @keypress="this.logoutTimer = this.logoutTime">
      </div>
    </div>
    <div class="row">
      <div>
        <small>Tuloksia {{filteredListCount}}kpl</small>
      </div>
    </div>
    <div class="row">
      <div>
        <br/>
        <div v-for="(student,index) in getFilteredList" :key="student.id" >
          <span v-if="index==0||getFilteredList[(index-1)].au!=student.au"><strong>{{student.au}}</strong></span>
          <div class="card" style="margin-bottom:5px;">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <strong>{{student.displayName}}</strong><br/>
                  {{student.userPrincipalName}}
                </div>
                <div class="col-2 d-flex justify-content-center align-content-center" v-if="isResetable(student)">
                  <button class="btn btn-default" @click="this.logoutTimer = this.logoutTime;resetPassword(student.id)">Resetoi</button>
                </div>
                <div class="col-2 d-flex justify-content-center align-content-center" v-if="!isResetable(student)">
                  <small>Tunnus poistettu käytöstä tai edellisestä salasanan vaihdosta on alle 24h.
                    {{getLocalTime(student)}}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'
import moment from 'moment'


export default {
  name: 'App',
  data() {
    return {
      accessToken: this.$store.accessToken,
      msal: this.$store.msalInstance,
      siteName: process.env.VUE_APP_SITENAME,
      username: '',
      students: [],
      searchString: '',
      searchIcon: 'bi bi-search',
      logoutTimer: 0,
      logoutTime: 1800
    }
  },
  async mounted() {

    this.init()

  },
  computed: {
    getFilteredList: function() {
            
      if (this.searchString.length < 3) {
        return this.students
      }

      return this.students.filter((item) => {
          if (item.displayName.includes(this.searchString) || item.userPrincipalName.includes(this.searchString)) {
            return item
          }
        });
    },
   filteredListCount: function() {
      return this.getFilteredList.length;
   }    
  },
  methods: {
    init: async function() {
      this.students = []
      //Käyttäjän tiedot
      let me = await axios.get(
        process.env.VUE_APP_API_ENDPOINT+'/me',
        {
            headers: { Authorization: 'Bearer '+this.accessToken }
        }
      )

      //Käyttäjän ryhmät
      let url = process.env.VUE_APP_API_ENDPOINT+'/me/memberOf?$top=999'

      let memberOfArr = []

      while(url) {
        let groups = await axios.get(
          url,
          {
              headers: { Authorization: 'Bearer '+this.accessToken }
          }
        )

        memberOfArr = memberOfArr.concat(groups.data.value)
        
        if (groups.data['@odata.nextLink']) url = groups.data['@odata.nextLink']
        else url = null
      }

      //Haetaan oppilat
      for (let i = 0;i<memberOfArr.length;i++){
        let memberOf = memberOfArr[i]
        
        if (memberOf['@odata.type'] == "#microsoft.graph.group" && memberOf['displayName'].includes('pwadminrole')) {
          let auName = memberOf['displayName'].replace('pwadminrole','pwaumember')

          let auRes = await axios.get(
            process.env.VUE_APP_API_ENDPOINT+'/administrativeUnits?$filter=startswith(displayName,\''+auName+'\')',
            {
                headers: { Authorization: 'Bearer '+this.accessToken }
            }
          )        
          if (auRes.data.value.length == 1) {
            let url = process.env.VUE_APP_API_ENDPOINT+'/administrativeUnits/'+auRes.data.value[0].id+'/members?$select=id,displayName,userPrincipalName,accountEnabled,lastPasswordChangeDateTime&$top=999'
            while(url) {
              let memberRes = await axios.get(
                url,
                {
                    headers: { Authorization: 'Bearer '+this.accessToken }
                }
              )

              for (let z = 0;z<memberRes.data.value.length;z++){
                let student = memberRes.data.value[z]
                if (me.data.userPrincipalName != student.userPrincipalName)
                  this.students.push({au: memberOf.description, userPrincipalName: student.userPrincipalName, displayName: student.displayName, id: student.id, accountEnabled: student.accountEnabled, lastReset: student.lastPasswordChangeDateTime})
              }

              if (memberRes.data['@odata.nextLink']) url = memberRes.data['@odata.nextLink']
              else url = null
            }
          }
        }
      }

      this.logoutTimer = this.logoutTime
      this.logoutOnTimer()
    },
    logoutOnTimer: function() {
      if (this.logoutTimer > 0) {
          setTimeout(() => {
              this.logoutTimer -= 1
              this.logoutOnTimer()
          }, 1000)
      }
      else {
        
        const logoutRequest = {
          account:  this.msal.getAccountByHomeId(this.$store.homeAccountId)
        }

        this.msal.logoutRedirect(logoutRequest)
      }
    },
    getLocalTime: function (student) {
      if (!student.lastReset) return ''
      let ltime = moment(student.lastReset).local().format('DD.MM.YYYY HH:mm')
      return "Salasana vaihdettu "+ltime
    },
    isResetable: function (student) {

      if (!student.lastReset) return true

      let locale_date = moment(student.lastReset)
      let now = moment()

      if (student.accountEnabled === true && now.diff(locale_date, 'hours') >= 24) return true
      else return false 
      
    },        
    Logout: function(){
      Swal.fire({
        title: 'Haluatko varmasti kirjautua ulos?',
        showCancelButton: true,
        confirmButtonText: 'Ok',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.msal.logoutRedirect();
        }
      })
    },
    generatePassword: function() {
      
      let password = ''

      let samelookin = /[ilLI|`oO0]/g

      var pattern = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$"
      )

      do {
        password = Math.random().toString(36).replace(samelookin,"").slice(-7)

        password = password.charAt(0).toUpperCase() + password.slice(1)

        password = password + String(Math.floor(Math.random() * 10)).replace("0","2") 
      }
      while(!pattern.test(password))

      return password
    },
    resetPassword: async function(id) {
      
      this.logoutTimer = this.logoutTime

      let password = this.generatePassword()

      //Haetaan password reset method
      let passwdMethod = await axios.get(
        process.env.VUE_APP_API_ENDPOINT+'/users/'+id+'/authentication/passwordMethods',
        {
            headers: { Authorization: 'Bearer '+this.accessToken }
        }
      )

      let data = {
        "newPassword": password,
      }
      
      Swal.fire({
        title: "Salasanaa nollataan...",
        text: "Ole hyvä ja odota",
        html: '<div style="height: 100px!important;"><div class="spinner-border" role="status"><span class="sr-only"></span></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: 'swal-height',
        heightAuto: false
      });

      axios.post(
        process.env.VUE_APP_API_ENDPOINT+'/users/'+id+'/authentication/passwordMethods/'+passwdMethod.data.value[0].id+'/resetPassword', 
        data, 
        {
            headers: { Authorization: 'Bearer '+this.accessToken }
        }
      )
      .then(() => {
        setTimeout(() => {
            let data = {
              "passwordProfile": {
                "forceChangePasswordNextSignIn": false,
                "forceChangePasswordNextSignInWithMfa": false,
                "password": password
              }
            }

            axios.patch(
              process.env.VUE_APP_API_ENDPOINT+'/users/'+id, 
              data, 
              {
                  headers: { Authorization: 'Bearer '+this.accessToken }
              }
            )
            .then(() => {
              Swal.fire(
                'Salasana nollattu!',
                'Uusi salasana on '+password,
                'success'
              )
              this.init()
            })
            .catch(() => {
              Swal.fire(
                'Salasanan nollaus epäonnistui!',
                'Voit yrittää hetken päästä uudelleen...',
                'error'
              )
            })
        }, 60000)
                
      })
      .catch(() => {
        Swal.fire(
          'Salasanan nollaus epäonnistui!',
          'Voit yrittää hetken päästä uudelleen...',
          'error'
        )
      })
    }
  }
}
</script>
<style scoped>
  .navbar {
    background-color : rgba(34, 46, 80, 1)!important;
    text-align: center;
  }
.swal-height {
  height: 200px!important;
}  
</style>
