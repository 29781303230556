import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from "vue-router";
import { PublicClientApplication } from '@azure/msal-browser';
import store from './store'

const msalInstance = new PublicClientApplication(store.state.msalConfig)
store.msalInstance = msalInstance

function handleResponse(response) {
    if (response !== null) {
        let accessToken = response.accessToken

        store.accessToken = accessToken
        store.homeAccountId = response.account.homeAccountId
        
        const routes = [
        ]

        const router = createRouter({
            history: createWebHistory(),
            routes,
        });

        const app = createApp(App)
        app.use(store)
        app.use(router)
        app.mount('#app')
    } else {
        msalInstance.loginRedirect({scopes: ['AdministrativeUnit.Read.All','Directory.AccessAsUser.All','Group.Read.All','User.Read','User.ReadBasic.All','UserAuthenticationMethod.ReadWrite.All']});
    }
}

msalInstance.handleRedirectPromise().then(handleResponse);
